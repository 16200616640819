// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "https://travona-api.myoptistech.com/v1/",
  // url: "http://172.17.0.171:7080/v1/",

  url_v2: "https://travona-api.myoptistech.com/v2/",
  ws_url: "https://tvn-chat-srv.myoptistech.com/",
  tinymce_api_key: "l4r9e340kvcv5oup4we5t769mvqjhf0tg56k84gy0q0oy334",

  // url: "http://192.168.88.191:7080/v1/",
  // url_v2: "http://192.168.88.191:7080/v2/",
  // ws_url: "http://192.168.88.191:4000/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
